<template>
  <div class="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8">
    <h3 class="text-2xl font-extrabold text-gray-900 mb-6">{{$t("profile.editHeadline")}}</h3>
    <div class="space-y-2">
      <InputWithError
        v-for="attr in Object.keys(attrLabels)"
        :key="attr"
        v-model="userData[attr]"
        id="attr"
        name="attr"
        type="text"
        :label="attrLabels[attr]"
        :placeholder="attrLabels[attr]"
        errorText=""
        :validation="v$.userData[attr]"
      />
      <UserSelect
        v-if="role.name == 'installer' && asps && asps.length"
        v-model="userData.asp"
        :label="$t('users.roleAps')"
        :people="asps"
        :show-all-options="true"
        :error="highlightAsp"
      />
    </div>
    <button
      @click="update"
      type="submit"
      :class="!changed ? 'pointer-events-none opacity-60' : ''"
      class="w-full flex justify-center mt-6 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-ifgreen hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen-dark"
    >
      {{$t("save")}}
    </button>
  </div>
</template>

<script>
import { api, patchHeaders } from "@/assets/js/api-client.js";
import InputWithError from '@/components/inputs/InputWithError.vue'
import UserSelect from '@/components/inputs/UserSelect.vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { mapState } from 'vuex'

const phone_format = (value) => {return /^\+[1-9]\d{1,14}$/.test(value)}
const tin_format = (value) => {return /^[0-9]{10}$/.test(value)}

export default {

    components: {
      InputWithError,
      UserSelect
    },
    setup () {
      return { v$: useVuelidate() }
    },
    data () {
      return {
        open: false,
        userData: {
          name: "",
          phoneNumber: "",
          company: "",
          tin: "",
          asp: null
        },
        currentData: {},
      }
    },
    validations () {
      return {
        userData: {
          name: { required: helpers.withMessage(this.$t('template.reqField'), required) },
          company: { required: helpers.withMessage(this.$t('template.reqField'), required) },
          tin: {
            required: helpers.withMessage(this.$t('template.reqField'), required),
            tin_format: helpers.withMessage(this.$t('public.errors.tin'), tin_format)
          },
          phoneNumber: {
            required: helpers.withMessage(this.$t('template.reqField'), required),
            phone_format: helpers.withMessage(this.$t('public.errors.phoneNumberFormat'), phone_format)
          }
        }
      }
    },
    computed: {
      ...mapState(['user','role','asps']),
      attrLabels () {
        return {
          name: `${this.$t("installation.customerName")}`,
          phoneNumber: `${this.$t("installation.phoneNumber")}`,
          company: `${this.$t("installation.companyName")}`,
          tin: this.$t("public.tin")
        }
      },
      changed () {
        console.log(this.$route)
        return JSON.stringify(this.userData) !== JSON.stringify(this.currentData)
      },
      highlightAsp () {
        return this.$route.query.chooseASP && !this.userData.asp
      }
    },
    async mounted() {
      this.userData = {
        name: this.user.name,
        phoneNumber: this.user.phoneNumber,
        company: this.user.company,
        tin: this.user.tin
      }
      if (this.role.name == 'installer') {
        if (!this.asps) await this.$store.dispatch('getAsps')
        if (this.asps.length && this.user.asp) this.userData['asp'] = this.asps.find(asp => {return this.user.asp.id == asp.id})
      }
      Object.assign(this.currentData, this.userData)
    },
    methods: {
      async update () {
        const data = {...this.userData, asp: this.userData.asp ? `/users/${this.userData.asp.id}` : null}

        this.$store.dispatch('setLoading', true)
        await api.patch(`/users/${this.user.id}`, data, patchHeaders)
        Object.assign(this.currentData, this.userData)
        const result = await api.get('/user')
        const { user } = result.data
        this.$store.dispatch('saveUser', user)
        this.$store.dispatch('setLoading', false)
        this.$notify({
          title: this.$t("public.success"),
          text: this.$t("public.infos.changesSaved"),
          type: "success"
        })
      },
    }
}
</script>
