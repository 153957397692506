<template>
  <PublicView :title="$t('public.registerTitle')">
    <form class="space-y-2" action="#" method="POST">
      <InputWithError
        v-if="enableRegistrationKeys"
        v-model="registrationKey"
        id="registrationKey"
        name="registrationKey"
        type="text"
        autocomplete=""
        :label="registrationKeyLabel"
        :placeholder="$t('public.certificateNumber')"
        errorText=""
        :validation="v$.registrationKey"
      />
      <InputWithError
        v-model="email"
        id="email"
        name="email"
        type="email"
        autocomplete="email"
        :label="$t('public.email')"
        placeholder="example@example.com"
        errorText=""
        :validation="v$.email"
      />
      <InputWithError
        v-model="password"
        id="password"
        name="password"
        type="password"
        autocomplete="current-password"
        :label="$t('public.password')"
        placeholder=""
        :errorText="$t('public.errors.password')"
        :validation="v$.password"
      />
      <InputWithError
        v-model="name"
        id="name"
        name="name"
        type="text"
        autocomplete="name"
        :label="$t('public.name')"
        :placeholder="$t('public.johnDoe')"
        :errorText="$t('tempalte.reqField')"
        :validation="v$.name"
      />
      <InputWithError
        v-model="company"
        id="company"
        name="company"
        type="text"
        autocomplete="company"
        :label="$t('public.company')"
        :placeholder="$t('installation.companyNamePlaceholder')"
        errorText=""
        :validation="v$.company"
      />
      <InputWithError
        v-model="tin"
        id="tin"
        name="tin"
        type="text"
        autocomplete="tin"
        :label="$t('public.tin')"
        placeholder=""
        errorText=""
        :validation="v$.tin"
      />
      <InputWithError
        v-model="phoneNumber"
        id="phone"
        name="phone"
        type="tel"
        autocomplete="phone"
        :label="$t('public.phone')"
        placeholder="+48000000000"
        errorText=""
        :validation="v$.phoneNumber"
      />

      <!--
      <div class="flex items-start">
        <input
          id="terms-of-service"
          name="terms-of-service"
          type="checkbox"
          required
          class="
            h-4
            w-4
            text-ifgreen
            focus:ring-ifgreen
            border-gray-300
            rounded
            mt-6
          "
        />
        <label
          for="terms-of-service"
          class="ml-2 mt-6 block text-sm text-gray-900"
        >
          Zapoznałem się z regulaminem korzystania z aplikacji
        </label>
      </div>

      <div class="flex items-center">
        <input
          id="rodo"
          name="rodo"
          type="checkbox"
          required
          class="
            h-4
            w-4
            text-ifgreen
            focus:ring-ifgreen
            border-gray-300
            rounded
          "
        />
        <label for="rodo" class="ml-2 block text-sm text-gray-900">
          Zapoznałem się z zasadami RODO
        </label>
      </div> -->

      <div>
        <button
          @click="signUp"
          type="submit"
          class="
            w-full
            flex
            justify-center
            mt-6
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-white
            bg-ifgreen
            hover:bg-ifgreen-dark
            focus:outline-none
            focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen-dark
          "
        >
          {{ $t('public.createAccount') }}
        </button>
      </div>
    </form>

    <div class="mt-6">
      <div class="relative">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center text-sm">
          <span class="px-2 bg-white text-gray-500"> {{ $t('public.haveAnAccount') }} </span>
        </div>
      </div>

      <div class="mt-6 flex justify-center">
        <router-link
          to="/login"
          class="font-medium text-ifgreen hover:text-ifgreen-dark"
        >
          {{ $t('public.login') }}
        </router-link>
      </div>
    </div>
  </PublicView>
</template>

<script>
import InputWithError from "@/components/inputs/InputWithError.vue"
import useVuelidate from '@vuelidate/core'
import PublicView from '@/components/layout/PublicView.vue'
import { required, helpers, email } from '@vuelidate/validators'
import { api } from "@/assets/js/api-client.js";

const policy = (value) => {return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value)}
const phone_format = (value) => {return /^\+[1-9]\d{1,14}$/.test(value)}
const tin_format = (value) => {return /^[0-9]{10}$/.test(value)}


export default {
  components: {
    InputWithError,
    PublicView
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      registrationKey: "",
      email: "",
      password: "",
      name: "",
      company: "",
      tin: "",
      phoneNumber: "",
    };
  },
  validations () {
    const rules = {
      email: {
        required: helpers.withMessage(this.$t('public.errors.required'), required),
        email: helpers.withMessage(this.$t('public.errors.email'), email)
      },
      password: {
        required: helpers.withMessage(this.$t('public.errors.required'), required),
        policy: helpers.withMessage(this.$t('public.errors.password'), policy)
      },
      name: { required: helpers.withMessage(this.$t('public.errors.required'), required) },
      company: { required: helpers.withMessage(this.$t('public.errors.required'), required) },
      tin: {
        required: helpers.withMessage(this.$t('public.errors.required'), required),
        tin_format: helpers.withMessage(this.$t('public.errors.tin'), tin_format)
      },
      phoneNumber: {
        required: helpers.withMessage(this.$t('public.errors.required'), required),
        phone_format: helpers.withMessage(this.$t('public.errors.phone'), phone_format)
      },
    }
    if (parseInt(process.env.VUE_APP_ENABLE_REGISTRATION_KEYS)) {
      rules.registrationKey = {
        required: helpers.withMessage(this.$t('public.errors.required'), required),
      }
    }
    return rules
  },
  computed: {
    enableRegistrationKeys () {
      return parseInt(process.env.VUE_APP_ENABLE_REGISTRATION_KEYS)
    },
    registrationKeyLabel () {
      return process.env.VUE_APP_REGISTRATION_KEY_LABEL
    }
  },
  methods: {
    async signUp(e) {
      e.preventDefault();
      this.v$.$touch()
      if (this.v$.$error) return
      this.$store.dispatch('setLoading', true)
      console.log("SigningUp!");
      const { email, password, name, company, tin, phoneNumber, registrationKey } = this;
      const user = {
        email,
        password,
        name,
        company,
        tin,
        phoneNumber,
        registrationKey,
        organisation: process.env.VUE_APP_ORG_ID,
        lang: this.$i18n.locale
      }
      api.post('/register', user).then(result => {
        console.log(result)
        this.$store.dispatch('setLoading', false)
        if (result.data.status == 'error' && result.data.code == 'wrong_registration_key') {
          this.$notify({
            title: this.$t('public.infos.wrongRegistrationKey'),
            text: this.$t('public.infos.checkRegistrationKey'),
            type: "error",
            duration: 15000
          })
        } else {
          this.$notify({
            title: this.$t('public.success'),
            text: this.$t('public.infos.accountCreated'),
            type: "success",
            duration: 15000
          })
          this.$router.push({name: 'login'})
        }
      }).catch(err => {
        this.$store.dispatch('setLoading', false)
        console.log(err)
        let errorMessage
        if (err.response.data.detail.includes('Duplicate entry')) {
          errorMessage = this.$t('public.errors.emailAlreadyUsed')
        } else {
          errorMessage = this.$t('public.infos.defaultError')
        }
        this.$notify({
          title: this.$t('public.infos.accountCreationError'),
          text: errorMessage,
          type: "error"
        })
      })
    }
  }
};
</script>


